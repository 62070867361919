import { ReactFragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';

import {
    Button,
    Flex,
    HStack,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    Text,
    VStack,
} from '@chakra-ui/react';

import { selectIsStaff } from '../reducers/auth';
import { selectIsAdmin, selectOrganization } from '../reducers/organization';
import theme from '../theme';
import { orgPageType, tabType } from '../types/types';
import {
    DASHBOARDS,
    DATA,
    HOME,
    NETWORK,
    PROFILE,
    SETTINGS,
    SHARED_DATA,
    USERS,
    YOUR_DATA,
} from '../util/constants';
import { useAppSelector } from '../util/hooks';
import {
    headerHeight,
    lightGrayLine,
    orgPageHeaderHeight,
    pageXBuffer,
} from '../util/styles';
import DelayedSpinner from './DelayedSpinner';

const { lightGray, darkGreen, darkGray } = theme.colors.brand;
const { black } = theme.colors.text;

const menuButtonStyle = {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '150%',
    textTransform: 'capitalize' as const,
    color: darkGreen,
};

interface TabLinkProps {
    text: string;
    tabKey: tabType;
    activeTab: tabType;
    setActiveTab: (tab: tabType) => void;
    setPage: (page: orgPageType) => void;
    isDisabled?: boolean;
}

interface TabMenuProps {
    text: string;
    tabKey: tabType;
    activeTab: tabType;
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    children?: ReactFragment | JSX.Element;
}

const TabLink = ({
    text,
    tabKey,
    activeTab,
    setActiveTab,
    setPage,
    isDisabled,
}: TabLinkProps) => (
    <Button
        variant='ghost'
        onClick={() => {
            setPage(tabKey as orgPageType);
            setActiveTab(tabKey);
        }}
        padding='0px'
        _hover={{ background: 'white' }}
        isDisabled={isDisabled === true}
    >
        <Text
            color={darkGreen}
            textTransform='capitalize'
            fontWeight={activeTab === tabKey ? 700 : 400}
        >
            {text}
        </Text>
    </Button>
);

const TabMenu = ({
    text,
    tabKey,
    activeTab,
    isOpen,
    setIsOpen,
    children,
}: TabMenuProps) => {
    return (
        <Menu onClose={() => setIsOpen(false)} placement='bottom-start'>
            <MenuButton
                as={Button}
                variant='ghost'
                padding='0px'
                _hover={{ background: 'white' }}
                _active={{ background: 'white' }}
                onClick={() => setIsOpen(!isOpen)}
            >
                <HStack alignItems='center' gap='6px'>
                    <Text
                        color={darkGreen}
                        textTransform='capitalize'
                        fontWeight={activeTab === tabKey ? 700 : 400}
                    >
                        {text}
                    </Text>
                    <Flex
                        pt='3px'
                        color={darkGray}
                        alignItems={'center'}
                        fontWeight={700}
                    >
                        {isOpen ? (
                            <BsChevronUp size={10} fontStyle='bold' />
                        ) : (
                            <BsChevronDown size={10} fontStyle='bold' />
                        )}
                    </Flex>
                </HStack>
            </MenuButton>
            {children}
        </Menu>
    );
};

const Divider = () => (
    <MenuDivider borderColor={lightGray} my={1} w='90%' mx='auto' />
);

const menuListStyle = {
    mt: 2,
    ml: -2,
    _focus: { outline: 'none' },
    p: 2,
    fontSize: '16px',
    lineHeight: '150%',
    fontWeight: 400,
};

interface OrganizationPageHeaderProps {
    tab: tabType;
    setTab: (tab: tabType) => void;
    page: orgPageType;
    setPage: (page: orgPageType) => void;
}

const OrganizationPageHeader = ({
    tab,
    setTab,
    page,
    setPage,
}: OrganizationPageHeaderProps) => {
    const { t } = useTranslation('organizationPage');
    const organization = useAppSelector(selectOrganization);
    const is_admin = useAppSelector(selectIsAdmin);
    const is_staff = useAppSelector(selectIsStaff);
    const [dataMenuOpen, setDataMenuOpen] = useState<boolean>(false);
    const [settingsMenuOpen, setSettingsMenuOpen] = useState<boolean>(false);

    if (!organization) {
        return <DelayedSpinner />;
    }
    return (
        <HStack
            width='100%'
            height={`${orgPageHeaderHeight}px`}
            backgroundColor='white'
            alignItems='center'
            justifyContent='space-between'
            borderBottom={lightGrayLine}
            padding={`0px ${pageXBuffer}px 0px ${pageXBuffer}px`}
            position='sticky'
            top={headerHeight}
            zIndex={100}
        >
            <VStack display='flex' alignItems='flex-start'>
                <Text
                    color={black}
                    fontSize='26px'
                    fontStyle='normal'
                    fontWeight='700'
                    lineHeight='120%'
                >
                    {organization.name}
                </Text>
                <HStack style={menuButtonStyle} gap='20px'>
                    <TabLink
                        text={t('home')}
                        tabKey={HOME}
                        activeTab={tab}
                        setActiveTab={setTab}
                        setPage={setPage}
                    />
                    <TabLink
                        text={t('dashboard')}
                        tabKey={DASHBOARDS}
                        activeTab={tab}
                        setActiveTab={setTab}
                        setPage={setPage}
                        // FUTURE re-enable this link once the page is complete
                        // https://github.com/thecosaorg/cosa-frontend/issues/1
                        isDisabled
                    />
                    <TabMenu
                        text={t('data')}
                        tabKey={DATA}
                        activeTab={tab}
                        isOpen={dataMenuOpen}
                        setIsOpen={setDataMenuOpen}
                    >
                        <MenuList {...menuListStyle}>
                            <MenuItem
                                onClick={() => {
                                    setTab(DATA);
                                    setPage(YOUR_DATA);
                                }}
                            >
                                {t('yourData')}
                            </MenuItem>
                            <Divider />
                            <MenuItem
                                onClick={() => {
                                    setTab(DATA);
                                    setPage(SHARED_DATA);
                                }}
                            >
                                {t('sharedData')}
                            </MenuItem>
                        </MenuList>
                    </TabMenu>
                    <TabLink
                        text={t('network')}
                        tabKey={NETWORK}
                        activeTab={tab}
                        setActiveTab={setTab}
                        setPage={setPage}
                    />
                    <TabMenu
                        text={t('settings')}
                        tabKey={SETTINGS}
                        activeTab={tab}
                        isOpen={settingsMenuOpen}
                        setIsOpen={setSettingsMenuOpen}
                    >
                        <MenuList {...menuListStyle}>
                            <MenuItem
                                onClick={() => {
                                    setTab(SETTINGS);
                                    setPage(PROFILE);
                                }}
                            >
                                {t('profile')}
                            </MenuItem>
                            {(is_admin || is_staff) && (
                                <>
                                    <Divider />
                                    <MenuItem
                                        // FUTURE: re-enable this once this page is complete
                                        // https://github.com/thecosaorg/cosa-frontend/issues/132
                                        isDisabled
                                        onClick={() => {
                                            setTab(SETTINGS);
                                            setPage(USERS);
                                        }}
                                    >
                                        {t('users')}
                                    </MenuItem>
                                </>
                            )}
                        </MenuList>
                    </TabMenu>
                </HStack>
            </VStack>
            {/* FUTURE: uncomment and implement once icons are supported 
            https://github.com/thecosaorg/cosa-frontend/issues/93 */}
            {/* <Flex
                backgroundColor={lightGray}
                boxSize={iconsSize}
                alignItems='center'
                justifyContent='center'
            >
                ICON
            </Flex> */}
        </HStack>
    );
};

export default OrganizationPageHeader;
