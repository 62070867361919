import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEffectOnce } from 'react-use';

import { Box, Flex, Image, Text } from '@chakra-ui/react';

import { selectLanguage } from '../../reducers/app';
import { selectOrganization } from '../../reducers/organization';
import theme from '../../theme';
import { useAppSelector } from '../../util/hooks';
import { pageXBuffer } from '../../util/styles';
import DelayedSpinner from '../DelayedSpinner';

const { lightOrange } = theme.colors.brand;

const Home = () => {
    const { t } = useTranslation('organizationPage');
    const organization = useAppSelector(selectOrganization);
    const language = useAppSelector(selectLanguage);

    const imageRef = useRef<HTMLDivElement>(null);
    const [isTooShort, setIsTooShort] = useState(false);
    const [isTooNarrow, setIsTooNarrow] = useState(false);

    const handleResize = () => {
        if (imageRef.current) {
            const widthBelowThreshold = imageRef.current.offsetWidth < 800;
            const heightBelowThreshold = imageRef.current.offsetHeight < 500;

            if (widthBelowThreshold !== isTooNarrow) {
                setIsTooNarrow(widthBelowThreshold);
            }

            if (heightBelowThreshold !== isTooShort) {
                setIsTooShort(heightBelowThreshold);
            }
        }
    };

    window.addEventListener('resize', handleResize);

    // This is important in case you load the page in window that
    // is either too wide or too short for the standard layout
    useEffectOnce(() => {
        handleResize();
    });

    const shouldShowText = !isTooNarrow || !isTooShort;
    const textPlacement = !isTooShort
        ? {
              bottom: 66,
              left: 100,
          }
        : {
              top: 66,
              right: 100,
          };

    if (!organization) {
        return <DelayedSpinner />;
    }
    return (
        <Flex
            width='100%'
            height='100%'
            alignItems='center'
            justifyContent='flex-start'
            position='relative'
            ref={imageRef}
        >
            <Image
                src='/img/trees.png'
                alt='login background'
                width='100%'
                height='100%'
                objectFit='cover'
            />
            <Image
                src='/img/sis-logo-white.svg'
                alt='SIS Logo'
                position='absolute'
                left={`${pageXBuffer}px`}
                top='66px'
                zIndex={1}
                height='160px'
                color='white'
            />
            {shouldShowText && (
                <Box
                    position='absolute'
                    {...textPlacement}
                    width={language === 'es' ? '235px' : '228px'}
                    fontSize='30px'
                >
                    <Text>
                        <span style={{ color: 'white', fontWeight: 400 }}>
                            {t('homePage1')}
                        </span>
                        <span style={{ fontWeight: 700, color: lightOrange }}>
                            {t('homePage2')}
                        </span>
                        <span style={{ color: 'white', fontWeight: 400 }}>
                            {t('homePage3')}
                        </span>
                    </Text>
                </Box>
            )}
        </Flex>
    );
};

export default Home;
