import { useTranslation } from 'react-i18next';
import OrgPageModal from './OrgPageModal';
import { VStack, Text, RadioGroup, Radio } from '@chakra-ui/react';
import { spacing1, spacing2 } from '../util/styles';
import { useState } from 'react';

interface DownloadTemplateProps {
    isOpen: boolean;
    onClose: () => void;
}

const ANNUAL = 'ANNUAL';
const ORGANIZATION = 'ORGANIZATION';
const ANOTHER = 'ANOTHER';

type templateTypeType = typeof ANNUAL | typeof ORGANIZATION | typeof ANOTHER;

const DownloadTemplateModal = ({ isOpen, onClose }: DownloadTemplateProps) => {
    const { t } = useTranslation('organizationPage');

    const [templateType, setTemplateType] =
        useState<templateTypeType>('ANNUAL');

    const changeHandler = (v: templateTypeType) => {
        setTemplateType(v);
    };

    return (
        <OrgPageModal
            isOpen={isOpen}
            onClose={onClose}
            title={t('downloadDataTemplate')}
            submitButtonTitle={t('downloadTemplate')}
            // FUTURE: implement this https://github.com/thecosaorg/cosa-frontend/issues/134
            onSubmit={() => console.log('DOWNLOADING TEMPLATE')}
            submitDisabled={false}
        >
            <VStack
                width='100%'
                alignItems='flex-start'
                gap={spacing1}
                paddingY={spacing2}
            >
                <Text variant='small'>{t('chooseOne')}</Text>
                <RadioGroup value={templateType} onChange={changeHandler}>
                    <VStack width='100%' alignItems='flex-start' gap={spacing1}>
                        <Radio value={ANNUAL} colorScheme='gray'>
                            {t('templateAnnual')}
                        </Radio>
                        <Radio colorScheme='gray' value={ORGANIZATION}>
                            {t('templateOrg')}
                        </Radio>
                        <Radio colorScheme='gray' value={ANOTHER}>
                            {t('templateAnother')}
                        </Radio>
                    </VStack>
                </RadioGroup>
            </VStack>
        </OrgPageModal>
    );
};

export default DownloadTemplateModal;
