import {
    Modal,
    ModalOverlay,
    ModalContent,
    VStack,
    Button,
    Text,
    Flex,
    Progress,
} from '@chakra-ui/react';
import { spacing6, spacing4, spacing12 } from '../util/styles';
import { useTranslation } from 'react-i18next';

interface UploadingModalProps {
    isOpen: boolean;
    onClose: () => void;
    onCancelUpload: () => void;
}

const UploadInProgressModal = ({
    isOpen,
    onClose,
    onCancelUpload,
}: UploadingModalProps) => {
    const { t } = useTranslation('organizationPage');

    return (
        <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
            <ModalOverlay />
            <ModalContent alignItems='center'>
                <VStack
                    paddingX={spacing6}
                    paddingY={spacing4}
                    width='440px'
                    borderRadius='6px'
                    gap={spacing12}
                    alignItems='flex-start'
                >
                    <Text variant='headingMd'>{t('uploadInProgress')}</Text>
                    <Progress borderRadius='4px' width='100%' isIndeterminate />
                    <Flex
                        alignItems='flex-end'
                        justifyContent='flex-end'
                        width='100%'
                    >
                        <Button
                            height='40px'
                            variant='primary'
                            onClick={onCancelUpload}
                        >
                            {t('cancel')}
                        </Button>
                    </Flex>
                </VStack>
            </ModalContent>
        </Modal>
    );
};

export default UploadInProgressModal;
