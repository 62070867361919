import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import {
    Button,
    Flex,
    HStack,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    VStack,
} from '@chakra-ui/react';

import {
    addOrganization,
    selectOrganizations,
    selectOrganizationsAreLoading,
} from '../reducers/organizations';
import theme from '../theme';
import {
    NewOrganizationParamsType,
    OrganizationType,
} from '../types/reducers/organizations';
import { Paths } from '../util/constants';
import { useAppDispatch, useAppSelector } from '../util/hooks';
import ClientStatusBadge from './ClientStatusBadge';
import PageHeading from './PageHeading';
import AddOrganizationModal from './AddOrganizationModal';
import { pagePadding } from '../util/styles';
import DelayedSpinner from './DelayedSpinner';

const { black } = theme.colors.text;
const { lightGray } = theme.colors.brand;

const OrganizationsPage = () => {
    const dispatch = useAppDispatch();
    const organizations = useAppSelector(selectOrganizations);
    const organizationsAreLoading = useAppSelector(
        selectOrganizationsAreLoading
    );
    const navigate = useNavigate();
    const { t } = useTranslation('organizationsPage');
    const finalRef = useRef<HTMLButtonElement>(null);

    const [displayAddOrgModal, setDisplayAddOrgModal] = useState(false);

    const addNewOrg = (formData: NewOrganizationParamsType) => {
        dispatch(addOrganization(formData));
        setDisplayAddOrgModal(false);
    };

    const renderRow = (organization: OrganizationType) => {
        const {
            id,
            name,
            organization_type: organizationType,
            client_status: clientStatus,
            created_by_email: createdBy,
        } = organization;

        return (
            <Tr
                key={id}
                sx={{
                    '&:hover': {
                        backgroundColor: lightGray,
                    },
                }}
                onClick={() => {
                    navigate(`${Paths.organizations}${id}`);
                }}
            >
                <Td fontWeight='700'>{name}</Td>
                <Td>
                    {organizationType === 'AUDITOR'
                        ? t('Auditor')
                        : t('Entity')}
                </Td>
                <Td>
                    <ClientStatusBadge clientStatus={clientStatus} />
                </Td>
                <Td>{createdBy}</Td>
            </Tr>
        );
    };

    return (
        <Flex
            width='100%'
            height='100%'
            alignItems='center'
            justifyContent='flex-start'
            flexDirection='column'
            padding={pagePadding}
        >
            <AddOrganizationModal
                finalRef={finalRef}
                isOpen={displayAddOrgModal}
                title={'New organization'}
                onCloseCallback={() => setDisplayAddOrgModal(false)}
                onSubmitCallback={addNewOrg}
            />
            <PageHeading title={t('title')} subheading={t('subheading')} />
            <VStack background='white' padding='24px' gap='24px' width='100%'>
                <HStack
                    display='flex'
                    justifyContent='space-between'
                    width='100%'
                >
                    <Text
                        style={{
                            color: black,
                            fontSize: '20px',
                            fontStyle: 'normal',
                            fontWeight: 700,
                            lineHeight: '120%',
                        }}
                    >
                        {t('allEntities')}
                    </Text>
                    <Button
                        style={{
                            display: 'flex',
                            height: '32px',
                            padding: '0px 12px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: 'var(--Spacing-2, 8px)',
                            color: black,
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 700,
                            lineHeight: '150%',
                        }}
                        onClick={() => setDisplayAddOrgModal(true)}
                        ref={finalRef}
                    >
                        {t('addOrganization')}
                    </Button>
                </HStack>
                {organizationsAreLoading ? (
                    <Flex width='100%' justifyContent='center'>
                        <DelayedSpinner />
                    </Flex>
                ) : (
                    <TableContainer
                        width='100%'
                        justifyContent='space-evenly'
                        overflowY='auto'
                    >
                        <Table variant='simple'>
                            <Thead>
                                <Tr>
                                    <Th>{t('name')}</Th>
                                    <Th>{t('type')}</Th>
                                    <Th>{t('dashboardStatus')}</Th>
                                    <Th>{t('createdBy')}</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {organizations.map((o: OrganizationType) =>
                                    renderRow(o)
                                )}
                            </Tbody>
                        </Table>
                    </TableContainer>
                )}
            </VStack>
        </Flex>
    );
};

export default OrganizationsPage;
