import {
    Button,
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    Text,
    HStack,
    Flex,
    VStack,
    Link,
} from '@chakra-ui/react';
import { useAppSelector } from '../util/hooks';
import { useTranslation } from 'react-i18next';

import { UploadType } from '../types/reducers/organizations';
import { spacing1, spacing2, spacing4, spacing6 } from '../util/styles';
import { selectLanguage } from '../reducers/app';

import theme from '../theme';
import { RiCloseFill } from 'react-icons/ri';
import { formatTimestamp } from '../util/utils';

const { lightGray, orange } = theme.colors.brand;
interface ModalProps {
    isOpen: boolean;
    upload: UploadType;
    onCloseCallback: () => void;
}

const UploadDetailDrawer = ({
    isOpen,
    upload,
    onCloseCallback,
}: ModalProps) => {
    const { t } = useTranslation('organizationPage');
    const { t: tc } = useTranslation('common');
    const language = useAppSelector(selectLanguage);

    // FUTURE: add in the sharing section of this sidebar once
    // sharing is supported https://github.com/thecosaorg/cosa-frontend/issues/131

    const downloadValidationNotes = () => {
        if (upload.get_validation_url) {
            const link = document.createElement('a');
            link.href = upload.get_validation_url;
            document.body.appendChild(link);
            try {
                link.click();
            } finally {
                document.body.removeChild(link);
            }
        }
    };
    return (
        <Drawer
            placement='right'
            size='md'
            variant='profile'
            isOpen={isOpen}
            onClose={onCloseCallback}
        >
            <DrawerOverlay />
            <DrawerContent padding={spacing6}>
                <DrawerHeader paddingLeft='0px' paddingRight='0px'>
                    <HStack
                        width='100%'
                        height='40px'
                        alignItems='center'
                        justifyContent='space-between'
                        paddingLeft='0px'
                    >
                        <Text variant='headingMd'>{upload.name}</Text>
                        <Flex
                            width='34px'
                            height='34px'
                            alignItems='center'
                            justifyContent='center'
                            onClick={onCloseCallback}
                            borderRadius='4px'
                            _hover={{
                                background: lightGray,
                            }}
                        >
                            <RiCloseFill height='24px' />
                        </Flex>
                    </HStack>
                </DrawerHeader>
                <DrawerBody padding='0px' height='100%'>
                    <VStack gap={spacing4} paddingY={spacing2}>
                        <VStack
                            width='100%'
                            alignItems='flex-start'
                            gap={spacing1}
                        >
                            {upload.get_url && (
                                <Link
                                    variant='underline'
                                    download={upload.original_filename}
                                    href={upload.get_url}
                                >
                                    {`${tc('download')} ${
                                        upload.original_filename
                                    }`}
                                </Link>
                            )}
                        </VStack>
                        {upload.get_validation_url && (
                            <VStack
                                display='flex'
                                alignItems='flex-start'
                                width='100%'
                                backgroundColor={'rgba(241, 136, 4, 0.05)'}
                                padding={spacing2}
                                gap={spacing2}
                                borderRadius='4px'
                            >
                                <Text variant='headingSm'>
                                    {t(upload.status)}
                                </Text>
                                <Text
                                    color={orange}
                                    fontSize='14px'
                                    fontStyle='normal'
                                    fontWeight='400'
                                    lineHeight='150%'
                                >
                                    {t(
                                        'Download validation notes to learn more.'
                                    )}
                                </Text>
                                <Button
                                    width='100%'
                                    height='40px'
                                    onClick={e => {
                                        e.preventDefault();
                                        downloadValidationNotes();
                                    }}
                                >
                                    <Text variant={'md'}>
                                        {t('Download validation notes')}
                                    </Text>
                                </Button>
                            </VStack>
                        )}
                        <Text alignSelf='flex-start' variant='headingSm'>
                            {t('information')}
                        </Text>
                        <HStack width='100%'>
                            <VStack
                                width='50%'
                                alignItems='flex-start'
                                gap={spacing1}
                            >
                                <Text variant='small'>{t('addedBy')}</Text>
                                <Text variant='small'>
                                    {upload.created_by_email}
                                </Text>
                            </VStack>
                            <VStack
                                width='50%'
                                alignItems='flex-start'
                                gap={spacing1}
                            >
                                <Text variant='small'>{t('date')}</Text>
                                <Text variant='small'>
                                    {formatTimestamp(
                                        upload.created_at,
                                        language
                                    )}
                                </Text>
                            </VStack>
                        </HStack>
                    </VStack>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};

export default UploadDetailDrawer;
