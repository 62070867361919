import { isNil } from 'lodash';
import React, { ChangeEvent, useRef } from 'react';
import { FiUpload } from 'react-icons/fi';
import { TfiClose } from 'react-icons/tfi';

import { Box, Flex, Input, InputGroup, Text } from '@chakra-ui/react';

import { darkGray, inputStyles } from '../util/styles';

interface CustomFileInputProps {
    file: File | undefined;
    setFile: (file: File | undefined) => void;
    placeholder: string;
}

const FileInput: React.FC<CustomFileInputProps> = ({
    file,
    setFile,
    placeholder,
}) => {
    const fileName = file?.name;
    const inputRef = useRef<HTMLInputElement>(null);

    const handleClick = () => {
        inputRef.current?.click();
    };

    const handleClear = () => {
        setFile(undefined);
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (!isNil(files)) {
            setFile(files[0]);
        }
    };

    return (
        <Flex alignItems='center' width='100%'>
            <InputGroup display='none'>
                <Input
                    ref={inputRef}
                    type='file'
                    onChange={handleFileChange}
                    multiple={false}
                    {...inputStyles}
                />
            </InputGroup>

            <Box
                as='button'
                onClick={isNil(file) ? handleClick : handleClear}
                borderWidth={1}
                borderStyle='solid'
                borderColor='gray.200'
                borderRadius='md'
                px={3}
                py={2}
                _hover={{ backgroundColor: 'gray.50' }}
                cursor='pointer'
                width='full'
            >
                <Flex alignItems='center' justifyContent='space-between'>
                    <Text fontSize='sm' color={file ? 'black' : 'gray.500'}>
                        {fileName || placeholder}
                    </Text>
                    {fileName ? (
                        <TfiClose size={12} color={darkGray} />
                    ) : (
                        <FiUpload size={16} color={darkGray} />
                    )}
                </Flex>
            </Box>
        </Flex>
    );
};

export default FileInput;
