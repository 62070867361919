import {
    AnyAction,
    createSlice,
    PayloadAction,
    ThunkDispatch,
} from '@reduxjs/toolkit';

import * as api from '../api';
import { RootState } from '../store';
import {
    NewOrganizationParamsType,
    OrganizationsState,
    OrganizationType,
} from '../types/reducers/organizations';
import { ThunkType } from '../types/types';
import { getErrorMessage } from '../util/utils';
import { setNextLocation } from './nextLocation';

const initialState: OrganizationsState = {
    organizations: [],
    loading: false,
    error: null,
};

export const organizationsSlice = createSlice({
    name: 'organizations',
    initialState,
    reducers: {
        startFetchOrganizations: (state: OrganizationsState) => {
            state.organizations = [];
            state.loading = true;
            state.error = null;
        },
        completeFetchOrganizations: (
            state: OrganizationsState,
            { payload }: PayloadAction<OrganizationType[]>
        ) => {
            state.organizations = payload;
            state.loading = false;
            state.error = null;
        },
        failFetchOrganizations: (
            state: OrganizationsState,
            { payload }: PayloadAction<string>
        ) => {
            state.organizations = [];
            state.loading = false;
            state.error = payload;
        },
        startAddNewOrganization: (state: OrganizationsState) => {
            state.loading = true;
            state.error = null;
        },
        completeAddNewOrganization: (
            state: OrganizationsState,
            { payload }: PayloadAction<OrganizationType>
        ) => {
            state.organizations = [...state.organizations, payload];
            state.loading = false;
            state.error = null;
        },
        failAddNewOrganization: (
            state: OrganizationsState,
            { payload }: PayloadAction<string>
        ) => {
            state.loading = false;
            state.error = payload;
        },
    },
});

export const {
    startFetchOrganizations,
    completeFetchOrganizations,
    failFetchOrganizations,

    startAddNewOrganization,
    completeAddNewOrganization,
    failAddNewOrganization,
} = organizationsSlice.actions;

export const fetchOrganizations =
    (): ThunkType =>
    async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
        dispatch(startFetchOrganizations());
        try {
            const data = await api.getOrganizations();
            dispatch(completeFetchOrganizations(data));
        } catch (e: unknown) {
            const message: string = getErrorMessage(
                e,
                'Failed to fetch organizations.'
            );

            dispatch(failFetchOrganizations(message));
        }
    };

export const addOrganization =
    (params: NewOrganizationParamsType): ThunkType =>
    async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
        dispatch(startAddNewOrganization());
        try {
            const data = await api.postNewOrganization(params);
            dispatch(completeAddNewOrganization(data));
            dispatch(setNextLocation({ origin: `/organizations/${data.id}/` }));
        } catch (e: unknown) {
            const message: string = getErrorMessage(
                e,
                'Failed to add new organization.'
            );

            dispatch(failAddNewOrganization(message));
        }
    };

export const selectOrganizations = (state: RootState) =>
    state.organizations.organizations;
export const selectOrganizationsAreLoading = (state: RootState) =>
    state.organizations.loading;

export default organizationsSlice.reducer;
