import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button, HStack, Image, Text } from '@chakra-ui/react';

import { selectUser } from '../reducers/auth';
import { fetchOrganizations } from '../reducers/organizations';
import theme from '../theme';
import { Paths } from '../util/constants';
import { useAppDispatch, useAppSelector } from '../util/hooks';
import { lightGrayLine, pageXBuffer } from '../util/styles';
import LanguageButton from './LanguageButton';
import { fetchOrganization } from '../reducers/organization';
import { selectIsProfileOpen, setIsProfileOpen } from '../reducers/app';

const { lightGreen } = theme.colors.brand;
const { midGray, black } = theme.colors.text;

const Header = () => {
    const dispatch = useAppDispatch();
    const user = useAppSelector(selectUser);
    const isProfileOpen = useAppSelector(selectIsProfileOpen);

    const is_staff = user != null && user.is_staff;

    useEffect(() => {
        if (is_staff) {
            // Staff users fetch all organizations initially
            dispatch(fetchOrganizations());
        } else {
            // Everyone else fetches just the one organization
            // they are associated with
            dispatch(fetchOrganization());
        }
    }, [dispatch, is_staff]);

    const { t } = useTranslation('header');

    const renderHeaderLink = (
        key: string,
        path: string,
        isDisabled: boolean = false
    ) => (
        <Button
            {...(!isDisabled && { as: Link, to: path })}
            variant='minimal'
            color={window.location.pathname === path ? black : midGray}
            isDisabled={isDisabled}
        >
            <Text>{t(key)}</Text>
        </Button>
    );

    return (
        <HStack
            display='flex'
            justifyContent='space-between'
            width='100%'
            height='50px'
            padding={`0px ${pageXBuffer}px 0px ${pageXBuffer}px`}
            borderTop={`4px solid ${lightGreen}`}
            borderBottom={lightGrayLine}
            position='sticky'
            top={0}
            bg='white'
            zIndex={100}
        >
            <Image src='/img/sis-logo-black.svg' alt='Cosa Logo' h='70%' />
            <HStack gap='10px'>
                {is_staff &&
                    renderHeaderLink('organizations', Paths.organizations)}
                {/* FUTURE: enable this once the users page is complete https://github.com/thecosaorg/cosa-frontend/issues/78 */}
                {is_staff && renderHeaderLink('users', Paths.users, true)}
                {is_staff && (
                    <Button
                        as='a'
                        // this string replacement is only relevant for dev
                        href={`${window.location.origin.replace(
                            'localhost:5678',
                            'localhost:7700'
                        )}${Paths.djangoAdmin}`}
                        target='_blank'
                        rel='noopener noreferrer'
                        variant='minimal'
                        color={midGray}
                    >
                        <Text>{t('permissions')}</Text>
                    </Button>
                )}
                <Button
                    variant='minimal'
                    color={isProfileOpen ? black : midGray}
                    onClick={() => dispatch(setIsProfileOpen(!isProfileOpen))}
                >
                    <Text>{t('profile')}</Text>
                </Button>
                <LanguageButton />
            </HStack>
        </HStack>
    );
};
export default Header;
