import {
    Modal,
    ModalOverlay,
    ModalContent,
    VStack,
    HStack,
    Text,
    Button,
    Icon,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { spacing3, spacing4, spacing6 } from '../util/styles';
import { BsX } from 'react-icons/bs';
import { ReactFragment } from 'react';

interface OrgPageModalProps {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    submitButtonTitle: string;
    onSubmit: () => void;
    submitDisabled: boolean;
    children?: ReactFragment | JSX.Element;
}

const OrgPageModal = ({
    isOpen,
    onClose,
    title,
    submitButtonTitle,
    onSubmit,
    submitDisabled,
    children,
}: OrgPageModalProps) => {
    const { t } = useTranslation('organizationPage');

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent alignItems='center'>
                <VStack
                    paddingX={spacing6}
                    paddingY={spacing4}
                    width='440px'
                    borderRadius='6px'
                    gap={spacing6}
                >
                    <HStack
                        width='100%'
                        alignItems='center'
                        justifyContent='space-between'
                    >
                        <Text variant='headingMd'>{title}</Text>
                        <Button
                            alignItems='center'
                            variant='minimal'
                            onClick={onClose}
                        >
                            <Icon as={BsX} boxSize='24px' />
                        </Button>
                    </HStack>
                    {children}
                    <HStack
                        alignItems='flex-end'
                        justifyContent='flex-end'
                        gap={spacing3}
                        width='100%'
                        pt={spacing3}
                    >
                        <Button
                            height='40px'
                            variant='primary'
                            onClick={onClose}
                        >
                            {t('cancel')}
                        </Button>
                        <Button
                            onClick={onSubmit}
                            height='40px'
                            variant='submit'
                            isDisabled={submitDisabled}
                        >
                            {submitButtonTitle}
                        </Button>
                    </HStack>
                </VStack>
            </ModalContent>
        </Modal>
    );
};

export default OrgPageModal;
