/*
    This file is valid in both Javascript and Typescript
*/
import { extendTheme } from '@chakra-ui/react';

// UTILS
const disabledStyles = {
    background: '#D3D3D3',
    color: '#3F3F46',
};

const Button = {
    baseStyle: {
        borderRadius: '4px',
    },
    variants: {
        primary: {
            background: 'brand.lightGray',
            color: 'text.black',
            _active: {
                background: 'white',
            },
            _disabled: disabledStyles,
            _hover: {
                background: '#3F3F46',
                _disabled: disabledStyles,
                color: 'white',
            },
        },
        minimal: {
            background: 'white',
            color: 'text.midGray',
            _disabled: {
                color: 'text.black',
            },
            _hover: {
                background: 'brand.lightGray',
            },
            fontSize: '16px',
            fontWeight: '400',
            padding: '0px 8px 0px 8px',
            textTransform: 'uppercase',
        },
        submit: {
            display: 'flex',
            alignItems: 'center',
            background: 'brand.darkGreen',
            color: 'white',
            radius: '4px',
            fontWeight: '700',
            fontSize: '16px',
            lineHeight: '150%',
            borderColor: 'brand.darkGreen',
            border: '1px solid',
            _hover: {
                background: 'white',
                color: 'brand.darkGreen',
                borderColor: 'brand.darkGreen',
                border: '1px solid',
            },
        },
        solid: {
            color: 'brand.black',
            radius: 'sm',
            background: 'brand.lightGray',
        },
        outline: {
            color: 'red',
            display: 'flex',
            alignItems: 'center',
            border: '1px solid',
            borderColor: 'red',
            radius: '4px',
            fontWeight: '700',
            fontSize: '16px',
            lineHeight: '150%',
            _hover: {
                background: 'red',
                color: 'white',
                border: '1px solid',
                borderColor: 'white',
            },
        },
    },
};

const Drawer = {
    variants: {
        profile: {
            size: 'md',
            boxShadow: '0px 4px 28.6px 0px #00000040',
            height: '100vh',
            padding: '16px',
        },
    },
};

const Text = {
    variants: {
        xsmall: {
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '150%' /* 18px */,
        },
        small: {
            color: 'brand.darkGray',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '150%' /* 21px */,
        },
        medium: {
            color: 'brand.black',
            fontSize: '15px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '150%' /* 24px */,
        },
        md: {
            color: 'brand.black',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: '150%',
        },
        headingSm: {
            fontSize: '16px',
            fontWeight: 700,
            lineHeight: '120%',
            textAlign: 'left',
            color: 'brand.black',
        },
        headingMd: {
            fontSize: '20px',
            fontWeight: 700,
            lineHeight: '24px',
            textAlign: 'left',
            color: 'brand.black',
        },
        headingSmall: {
            fontSize: '18px',
            fontWeight: 400,
            lineHeight: '27px',
            textAlign: 'left',
            color: 'brand.darkGray',
        },
        label: {
            fontSize: '12px',
            fontWeight: 700,
            lineHeight: '16px',
            letterSpacing: '0.03em',
            textAlign: 'left',
            color: 'white',
        },
    },
};

const theme = extendTheme({
    components: {
        Button,
        Text,
        Drawer,
    },
    colors: {
        brand: {
            darkGreen: '#227450',
            green: '#38A169',
            lightGreen: '#3CC17C',
            lightGray: '#EBEBEB',
            darkGray: '#575757',
            black: '#0C0C0C',
            orange: '#DD6B20',
            lightOrange: '#F18804',
        },
        text: {
            midGray: '#757575',
            black: '#0C0C0C',
            orange: '#AB6003',
        },
    },
});

export default theme;
