// Place to store reusable styling configurations

import theme from '../theme';

export const { lightGreen, lightGray, darkGray } = theme.colors.brand;
export const { midGray } = theme.colors.text;

// pixel buffer on the sides of all pages
export const pageXBuffer = 100;
export const pageYBuffer = 40;
export const pagePadding = `${pageYBuffer}px ${pageXBuffer}px ${pageYBuffer}px ${pageXBuffer}px`;

export const lightGrayLine = `1px solid ${lightGray}`;
export const iconsSize = '74px';

export const selectStyle = {
    width: '100%',
    border: `1px solid ${lightGray}`,
    borderRadius: '2px',
    background: 'white',
    _focus: {
        border: `1px solid ${darkGray}`,
    },
};

export const inputStyles = {
    width: '100%',
    border: `1px solid ${lightGray}`,
    borderRadius: '2px',
    focusBorderColor: darkGray,
    _placeholder: {
        color: midGray,
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '150%',
    },
    color: 'black',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '150%' /* 21px */,
};

export const smallTextStyle = {
    color: darkGray,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '150%' /* 21px */,
};

export const modalWidth = '440px';

// Spacing
export const spacing1 = '4px';
export const spacing2 = '8px';
export const spacing3 = '12px';
export const spacing4 = '16px';
export const spacing5 = '20px';
export const spacing6 = '24px';
export const spacing8 = '32px';
export const spacing10 = '40px';
export const spacing12 = '48px';

export const radius = {
    s: '4px',
    m: '8px',
};

export const baseContainerStyle = {
    display: 'flex',
    padding: 'var(--Spacing-6, 24px)',
    alignItems: 'start',
    gap: 'var(--Spacing-6, 24px)',
    alignSelf: 'stretch',
    borderRadius: 'var(--Radius-s, 4px)',
    background: '#FFF',
};

export const headerHeight = 50;
export const orgPageHeaderHeight = 90;
export const footerHeight = 60;
