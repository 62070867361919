import {
    AnyAction,
    createSlice,
    PayloadAction,
    ThunkDispatch,
} from '@reduxjs/toolkit';

import * as api from '../api';
import { RootState } from '../store';
import { LoginType, UserType } from '../types/reducers/auth';
import { ThunkType } from '../types/types';

interface LoginState {
    login: LoginType | null;
    loading: boolean;
    error: string | null;
}

const initialLoginState = {
    is_authenticated: false,
    is_authorized: false,
    user: null,
    error_message: null,
};

const initialState: LoginState = {
    login: initialLoginState,
    loading: true,
    error: null,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        startLogin: () => ({
            login: initialLoginState,
            loading: true,
            error: null,
        }),
        completeLogin: (
            state: LoginState,
            { payload }: PayloadAction<LoginType>
        ) => {
            state.login = payload;
            state.loading = false;
            state.error = null;
        },
        failLogin: (state: LoginState, { payload }: PayloadAction<string>) => {
            state.login = initialLoginState;
            state.loading = false;
            state.error = payload;
        },
        startLogout: (state: LoginState) => {
            state.loading = true;
            state.error = null;
        },
        completeLogout: (state: LoginState) => {
            state.login = initialLoginState;
            state.loading = initialState.loading;
            state.error = initialState.error;
        },
        failLogout: (state: LoginState, { payload }: PayloadAction<string>) => {
            state.loading = false;
            state.error = payload;
        },
        setUserInfo: (
            state: LoginState,
            { payload }: PayloadAction<UserType>
        ) => {
            if (state.login) {
                state.login.user = payload;
            }
        },
    },
});

export const { startLogin, completeLogin, failLogin, setUserInfo } =
    authSlice.actions;

export const loginWithSessionId =
    (): ThunkType =>
    async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
        dispatch(startLogin());
        try {
            const data = await api.getLoginWithSessionId();
            dispatch(completeLogin(data));
        } catch (e: unknown) {
            dispatch(failLogin(''));
        }
    };

export const selectLogin = ({ auth }: RootState) => auth.login;
export const selectAuthLoading = ({ auth }: RootState) => auth.loading;
export const selectUser = ({ auth }: RootState) =>
    auth.login ? auth.login.user : null;
export const selectPreferredLanguage = ({ auth }: RootState) =>
    auth.login ? auth.login.user?.preferred_language : null;
export const selectIsStaff = ({ auth }: RootState) =>
    auth.login?.user ? auth.login.user?.is_staff : false;

export default authSlice.reducer;
