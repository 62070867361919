import { ReactFragment } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { Flex } from '@chakra-ui/react';

import { selectAuthLoading, selectUser } from '../reducers/auth';
import theme from '../theme';
import { Paths } from '../util/constants';
import { useAppDispatch, useAppSelector } from '../util/hooks';
import DelayedSpinner from './DelayedSpinner';
import Footer from './Footer';
import Header from './Header';
import ErrorPage from './ErrorPage';
import {
    clearNextLocation,
    selectNextLocation,
} from '../reducers/nextLocation';
import ProfileDrawer from './ProfileDrawer';
import { selectIsProfileOpen } from '../reducers/app';

const { lightGray } = theme.colors.brand;

interface ProtectedRouteProps {
    adminOnly?: boolean;
    children?: ReactFragment | JSX.Element;
}

const ProtectedRoute = ({ adminOnly, children }: ProtectedRouteProps) => {
    const dispatch = useAppDispatch();
    const authLoading = useAppSelector(selectAuthLoading);
    const user = useAppSelector(selectUser);
    const nextLocation = useAppSelector(selectNextLocation);
    const isProfileOpen = useAppSelector(selectIsProfileOpen);
    const location = useLocation();
    const loginPath = Paths.login;

    if (authLoading === true) {
        return <DelayedSpinner />;
    }
    if (!user) {
        return <Navigate to={loginPath} replace state={{ from: location }} />;
    }

    const { is_staff } = user;

    if (adminOnly && !is_staff) {
        return (
            <ErrorPage pageError='You do not have sufficient permissions to access this page' />
        );
    }

    if (nextLocation) {
        if (nextLocation === location?.pathname) {
            dispatch(clearNextLocation());
        } else {
            return <Navigate to={nextLocation} />;
        }
    }

    return (
        <Flex
            flexFlow='column'
            height='100vh'
            justifyContent='space-between'
            overflow='auto'
        >
            {user && (
                <ProfileDrawer user={user} isProfileOpen={isProfileOpen} />
            )}
            <Header />
            <Flex
                flexGrow={1}
                width='100%'
                backgroundColor={lightGray}
                alignItems='flex-start'
            >
                {children}
            </Flex>
            <Footer />
        </Flex>
    );
};

export default ProtectedRoute;
