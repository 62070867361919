import { Suspense } from 'react';
import { createBrowserRouter } from 'react-router-dom';

import ErrorPage from './components/ErrorPage';
import LandingPage from './components/LandingPage';
import Login from './components/Login';
import OrganizationPage from './components/OrganizationPage';
import OrganizationsPage from './components/OrganizationsPage';
import Privacy from './components/Privacy';
import ProtectedRoute from './components/ProtectedRoute';
import SiteWrapper from './components/SiteWrapper';
import { Paths } from './util/constants';
import OrgUsersPage from './components/OrgUsersPage';
import OrgDataPage from './components/OrgDataPage';
import UsersPage from './components/UsersPage';

const router = createBrowserRouter([
    {
        path: Paths.login,
        element: <SiteWrapper />,
        errorElement: (
            <Suspense>
                <ErrorPage />
            </Suspense>
        ),
        children: [
            {
                path: Paths.login,
                element: <Login />,
            },
            {
                path: Paths.landing,
                element: (
                    <ProtectedRoute>
                        <LandingPage />
                    </ProtectedRoute>
                ),
            },
            {
                path: Paths.organizations,
                element: (
                    <ProtectedRoute adminOnly>
                        <OrganizationsPage />
                    </ProtectedRoute>
                ),
            },
            {
                path: Paths.organization,
                element: (
                    <ProtectedRoute>
                        <OrganizationPage />
                    </ProtectedRoute>
                ),
            },
            {
                path: Paths.orgUsers,
                element: (
                    <ProtectedRoute>
                        <OrgUsersPage />
                    </ProtectedRoute>
                ),
            },
            {
                path: Paths.orgData,
                element: (
                    <ProtectedRoute>
                        <OrgDataPage />
                    </ProtectedRoute>
                ),
            },
            {
                path: Paths.users,
                element: (
                    <ProtectedRoute adminOnly>
                        <UsersPage />
                    </ProtectedRoute>
                ),
            },
            {
                path: Paths.privacy,
                element: <Privacy />,
            },
        ],
    },
]);

export default router;
