import { Text, Image, HStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import theme from '../theme';
import { footerHeight, pagePadding } from '../util/styles';

const { lightGray, darkGray } = theme.colors.brand;

const Footer = () => {
    const { t } = useTranslation('footer');

    return (
        <HStack
            alignItems='center'
            width='100%'
            backgroundColor={lightGray}
            height={`${footerHeight}px`}
            justifyContent='center'
            gap='8px'
            padding={pagePadding}
            zIndex={50}
        >
            <Text color={darkGray} variant='xsmall'>
                {t('poweredBy')}
            </Text>
            <Image
                src='/img/cosa-logo.svg'
                alt='Coso Logo'
                h='40px'
                paddingBottom='10px'
            />
        </HStack>
    );
};
export default Footer;
