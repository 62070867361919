import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import {
    Button,
    Container,
    Flex,
    HStack,
    Image,
    Link,
    VStack,
    Text,
} from '@chakra-ui/react';

import { selectLogin, selectUser } from '../reducers/auth';
import { setNextLocation } from '../reducers/nextLocation';
import { Paths, URLs } from '../util/constants';
import { useAppDispatch, useAppSelector } from '../util/hooks';
import ErrorAlert from './ErrorAlert';
import ErrorPage from './ErrorPage';

const LOGIN_BUTTON_WIDTH = '160px';

const Auth0Login = () => {
    const location = useLocation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation('auth0Login');
    const { t: tf } = useTranslation('footer');
    const [searchParams] = useSearchParams();
    const login = useAppSelector(selectLogin);
    const user = useAppSelector(selectUser);

    const error = searchParams.get('error');
    const origin = location.state?.from?.pathname;

    useEffect(() => {
        if (origin) {
            dispatch(setNextLocation({ origin }));
        }
    }, [origin, dispatch]);

    useEffect(() => {
        if (user) {
            if (origin === Paths.login || !origin) {
                if (user.is_staff) {
                    return navigate(Paths.organizations);
                } else {
                    return navigate(Paths.landing);
                }
            }
            return navigate(origin);
        }
    }, [user, navigate, origin]);

    if (!!error) return <ErrorPage pageError={error} />;

    return (
        <VStack
            w='100%'
            minH='100vh'
            bg='white'
            alignItems='center'
            overflowY='auto'
            justifyContent='flex-start'
            gap='20px'
            paddingTop='50px'
            paddingBottom='50px'
        >
            <VStack
                w='100%'
                maxW='400px'
                height='100%'
                alignItems='stretch'
                spacing='40px'
                justifyContent='space-between'
            >
                <VStack
                    width='100%'
                    alignItems='center'
                    justifyContent='space-between'
                >
                    <Image
                        src='/img/sis-logo-black.svg'
                        alt='cosa logo'
                        width={LOGIN_BUTTON_WIDTH}
                    />
                </VStack>
                <Flex
                    width='100%'
                    alignItems='center'
                    justifyContent='center'
                    py='50px'
                >
                    <VStack>
                        {login?.error_message && (
                            <ErrorAlert error={t(login.error_message)} />
                        )}
                        {!login?.is_authenticated ? (
                            <Button
                                variant='submit'
                                width='fit-content'
                                onClick={e => {
                                    e.preventDefault();
                                    window.location.assign(
                                        URLs.makeBrowserURL(
                                            'api/auth0/authenticate/'
                                        )
                                    );
                                }}
                            >
                                {t('signIn')}
                            </Button>
                        ) : (
                            <VStack>
                                {login && !login.is_authorized && (
                                    <ErrorAlert
                                        error={t('inactiveError', {
                                            email: window.ENVIRONMENT
                                                ?.REACT_APP_SUPPORT_EMAIL,
                                        })}
                                    />
                                )}
                                <form
                                    style={{ width: '100%' }}
                                    onSubmit={e => {
                                        e.preventDefault();
                                        window.location.assign(
                                            URLs.makeBrowserURL(
                                                'api/auth0/logout/'
                                            )
                                        );
                                    }}
                                >
                                    <VStack mt='50px' mb='50px' spacing='10px'>
                                        <Button type='submit'>
                                            {t('logOut')}
                                        </Button>
                                    </VStack>
                                </form>
                                {login?.is_authorized && (
                                    <Container>
                                        {t('loggedInAs', {
                                            email: login?.user?.email,
                                        })}
                                    </Container>
                                )}
                            </VStack>
                        )}
                        <Link color='#757575' href='/privacy/'>
                            {t('privacy')}
                        </Link>
                    </VStack>
                </Flex>
                <HStack alignItems='center' justifyContent='center'>
                    <Text variant='xsmall'>{tf('poweredBy')}</Text>
                    <Image
                        src='/img/cosa-logo.svg'
                        alt='Coso Logo'
                        h='40px'
                        paddingBottom='10px'
                    />
                </HStack>
            </VStack>
        </VStack>
    );
};

export default Auth0Login;
