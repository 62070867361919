import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Flex,
    HStack,
    Spinner,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    VStack,
} from '@chakra-ui/react';

import {
    fetchDatasets,
    selectDatasets,
    selectOrganization,
    selectOrganizationIsLoading,
} from '../../reducers/organization';
import { useAppDispatch, useAppSelector } from '../../util/hooks';
import {
    lightGray,
    spacing1,
    spacing10,
    spacing2,
    spacing6,
    spacing8,
} from '../../util/styles';
import PageHeading from '../PageHeading';
import {
    DatasetFormatType,
    DatasetType,
} from '../../types/reducers/organizations';
import { selectUser } from '../../reducers/auth';
import { formatTimestamp } from '../../util/utils';
import { selectLanguage } from '../../reducers/app';

import { RiFileDownloadLine, RiSortAsc, RiSortDesc } from 'react-icons/ri';

interface SortType {
    key: keyof DatasetType;
    sortDescending: boolean;
}

const SharedData = () => {
    const { t } = useTranslation('organizationPage');
    const dispatch = useAppDispatch();
    const organization = useAppSelector(selectOrganization);
    const datasets = useAppSelector(selectDatasets);
    const user = useAppSelector(selectUser);
    const loading = useAppSelector(selectOrganizationIsLoading);
    const language = useAppSelector(selectLanguage);

    // FUTURE remove this client-side sorting when API supports it
    // Default sort shows the most recent at the top
    const [sortBy, setSortBy] = useState<SortType>({
        key: 'date',
        sortDescending: true,
    });

    const updateSortBy = (key: keyof DatasetType) => {
        if (sortBy.key === key) {
            setSortBy({
                key: sortBy.key,
                sortDescending: !sortBy.sortDescending,
            });
        } else {
            setSortBy({
                key: key,
                sortDescending: false,
            });
        }
    };

    useEffect(() => {
        if (organization) {
            dispatch(fetchDatasets(organization.id));
        }
    }, [organization, dispatch]);

    if (!organization || !user || loading) {
        return (
            <Flex width='100%' justifyContent='center'>
                <Spinner />
            </Flex>
        );
    }

    const getDatasetValue = (dataset: DatasetType, key: keyof DatasetType) =>
        dataset[key];

    const sortedDatasets = [...datasets];
    if (sortBy) {
        sortedDatasets.sort((a: DatasetType, b: DatasetType) => {
            const aVal = getDatasetValue(a, sortBy.key);
            const bVal = getDatasetValue(b, sortBy.key);
            if (aVal > bVal) {
                return 1;
            } else if (aVal < bVal) {
                return -1;
            }
            return 0;
        });
        if (sortBy.sortDescending) {
            sortedDatasets.reverse();
        }
    }

    const renderFormatLinks = (formats: DatasetFormatType[]) => (
        <HStack gap='1rem'>
            {formats.map(f => (
                <HStack gap='0.1rem'>
                    <a href={f.download_url} download>
                        <RiFileDownloadLine />
                    </a>
                    <a href={f.download_url} download>
                        {f.type}
                    </a>
                </HStack>
            ))}
        </HStack>
    );

    const renderRow = (dataset: DatasetType) => {
        const {
            dataset_id: id,
            date,
            name,
            category,
            reference,
            formats,
        } = dataset;

        return (
            <Tr
                key={id}
                sx={{
                    '&:hover': {
                        backgroundColor: lightGray,
                    },
                }}
            >
                <Td>{formatTimestamp(date, language)}</Td>
                <Td>{name}</Td>
                <Td>{reference}</Td>
                <Td>{category}</Td>
                <Td>{renderFormatLinks(formats)}</Td>
            </Tr>
        );
    };

    const headerCellContent = (key: keyof DatasetType) => {
        if (key === sortBy.key) {
            const icon = sortBy.sortDescending ? <RiSortDesc /> : <RiSortAsc />;
            return (
                <HStack>
                    <span>{t(key)}</span>
                    {icon}
                </HStack>
            );
        }
        return t(key);
    };

    return (
        <VStack
            width='100%'
            height='100%'
            alignItems='center'
            justifyContent='flex-start'
            gap={spacing10}
        >
            <PageHeading title={t('sharedData')} />
            <VStack
                padding={spacing6}
                width='100%'
                backgroundColor='white'
                gap={spacing8}
            >
                <HStack
                    alignItems='center'
                    justifyContent='space-between'
                    width='100%'
                >
                    <VStack gap={spacing1} alignItems='flex-start'>
                        <Text variant='headingMd'>{t('yourInsightsData')}</Text>
                        <Text variant='small'>
                            {t('yourInsightsDataDescription')}
                        </Text>
                    </VStack>
                </HStack>

                {sortedDatasets.length > 0 ? (
                    <TableContainer
                        width='100%'
                        justifyContent='space-evenly'
                        overflowY='auto'
                    >
                        <Table variant='simple'>
                            <Thead>
                                <Tr>
                                    <Th
                                        sx={{ cursor: 'pointer' }}
                                        onClick={() => updateSortBy('date')}
                                    >
                                        {headerCellContent('date')}
                                    </Th>
                                    <Th
                                        sx={{ cursor: 'pointer' }}
                                        onClick={() => updateSortBy('name')}
                                    >
                                        {headerCellContent('name')}
                                    </Th>
                                    <Th
                                        sx={{ cursor: 'pointer' }}
                                        onClick={() =>
                                            updateSortBy('reference')
                                        }
                                    >
                                        {headerCellContent('reference')}
                                    </Th>
                                    <Th
                                        sx={{ cursor: 'pointer' }}
                                        onClick={() => updateSortBy('category')}
                                    >
                                        {headerCellContent('category')}
                                    </Th>
                                    <Th>{t('file')}</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {sortedDatasets.map((d: DatasetType) =>
                                    renderRow(d)
                                )}
                            </Tbody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Flex
                        width='100%'
                        minH='90px'
                        border={`1px solid ${lightGray}`}
                        padding={spacing2}
                        alignItems='center'
                        justifyContent='center'
                    >
                        <Text variant='small'>{t('none')}</Text>
                    </Flex>
                )}
            </VStack>
        </VStack>
    );
};

export default SharedData;
