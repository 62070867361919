import axios, { CancelTokenSource } from 'axios';
import { isUndefined } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Checkbox,
    Input,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useToast,
    VStack,
} from '@chakra-ui/react';

import { completeUpload, createUpload } from '../api';
import { selectUser } from '../reducers/auth';
import { fetchUploads, selectOrganization } from '../reducers/organization';
import { OrganizationType } from '../types/reducers/organizations';
import { useAppDispatch, useAppSelector } from '../util/hooks';
import {
    inputStyles,
    spacing1,
    spacing2,
    spacing4,
    spacing6,
    spacing8,
} from '../util/styles';
import FileInput from './FileInput';
import OrgPageModal from './OrgPageModal';
import UploadInProgressModal from './UploadInProgressModal';
import DelayedSpinner from './DelayedSpinner';

interface UploadDataModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const UploadDataModal = ({ isOpen, onClose }: UploadDataModalProps) => {
    const { t } = useTranslation('organizationPage');
    const user = useAppSelector(selectUser);
    const dispatch = useAppDispatch();
    const organization = useAppSelector(selectOrganization);
    const toast = useToast();

    const [name, setName] = useState<string>('');
    const [file, setFile] = useState<File | undefined>();
    const [shareOrgs, setShareOrgs] = useState<string[]>([]);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [cancelToken, setCancelToken] = useState<CancelTokenSource | null>(
        null
    );

    if (!organization) {
        return <DelayedSpinner />;
    }

    const handleCheckboxChange = (id: string) => {
        setShareOrgs(prevSelected =>
            prevSelected.includes(id)
                ? prevSelected.filter(orgId => orgId !== id)
                : [...prevSelected, id]
        );
    };

    const handleUploadFailure = (error?: string) => {
        toast({
            position: 'top',
            title: error ? t('uploadFailureMessage') : t('failure'),
            description: error ? error : t('uploadFailureMessage'),
            status: 'error',
            duration: 3000,
            isClosable: true,
        });
    };

    const handleUploadSuccess = () => {
        setIsUploading(false);
        dispatch(fetchUploads(organization.id));
        onClose();
    };

    // FUTURE: populate this with actual organizations once we support
    // data sharing https://github.com/thecosaorg/cosa-frontend/issues/131
    const orgsToShareWith: OrganizationType[] = [];

    const handleSubmit = async () => {
        if (name && file && user && organization) {
            // Delay the isUploading modal to avoid it
            // flashing really quickly
            setTimeout(() => {
                setIsUploading(true);
            }, 1000);

            const uploadCancelToken = axios.CancelToken.source();
            setCancelToken(uploadCancelToken);

            try {
                const data = {
                    name: name,
                    original_filename: file.name,
                    created_by_id: user.id,
                };
                // Send a request to django creating the upload object with a signed url
                const uploadData = await createUpload(organization.id, data);

                // Upload the file to google cloud
                const { put_url: putUrl, id: uploadId } = uploadData;
                await axios.put(putUrl, file, {
                    headers: {
                        'Content-Type': 'application/octet-stream',
                    },
                    cancelToken: uploadCancelToken.token,
                });

                // Mark the upload as complete
                await completeUpload(organization.id, uploadId);

                // on success
                handleUploadSuccess();
            } catch (error) {
                if (axios.isCancel(error)) {
                    handleUploadFailure(t('cancelMessage'));
                } else if (axios.isAxiosError(error)) {
                    const errorMessage: string =
                        error.response?.data || error.message;
                    handleUploadFailure(errorMessage);
                } else {
                    handleUploadFailure(error as string);
                }
            }
        } else {
            handleUploadFailure();
        }
    };

    const handleCancelUpload = () => {
        setIsUploading(false);
        if (cancelToken) {
            cancelToken.cancel(t('cancelMessage'));
        }
    };

    if (isUploading) {
        return (
            <UploadInProgressModal
                isOpen={isOpen}
                onClose={onClose}
                onCancelUpload={handleCancelUpload}
            />
        );
    }

    return (
        <OrgPageModal
            isOpen={isOpen}
            onClose={() => {
                setName('');
                setFile(undefined);
                onClose();
            }}
            title={t('uploadData')}
            submitButtonTitle={t('upload')}
            onSubmit={handleSubmit}
            submitDisabled={isUndefined(name) || isUndefined(file)}
        >
            <VStack
                width='100%'
                alignItems='flex-start'
                gap={spacing8}
                paddingY={spacing2}
            >
                <VStack
                    gap={spacing4}
                    paddingY='2px'
                    width='100%'
                    alignItems='flex-start'
                >
                    <Text variant='headingSm'>{t('generalInformation')}</Text>
                    <VStack width='100%' gap={spacing1} alignItems='flex-start'>
                        <Text variant='small'>{t('name')}</Text>
                        <Input
                            placeholder={t('enterName')}
                            value={name}
                            {...inputStyles}
                            onChange={e => setName(e.target.value)}
                        />
                    </VStack>
                </VStack>
                <VStack
                    gap={spacing4}
                    paddingY='2px'
                    width='100%'
                    alignItems='flex-start'
                >
                    <Text variant='headingSm'>{t('file')}</Text>
                    <FileInput
                        file={file}
                        setFile={setFile}
                        placeholder={t('choose')}
                    />
                </VStack>
                {orgsToShareWith.length && (
                    <VStack
                        gap={spacing4}
                        paddingBottom='2px'
                        paddingTop={spacing6}
                        width='100%'
                        alignItems='flex-start'
                    >
                        <Text variant='headingSm'>{t('shareWith')}</Text>
                        <VStack
                            width='100%'
                            gap={spacing1}
                            alignItems='flex-start'
                        >
                            <Table variant='simple'>
                                <Thead>
                                    <Tr>
                                        <Th
                                            textTransform='capitalize'
                                            width='20px'
                                            textAlign='center'
                                            paddingX='0px'
                                        >
                                            <Text variant='small'>
                                                {t('share')}
                                            </Text>
                                        </Th>
                                        <Th textTransform='capitalize'>
                                            <Text variant='small'>
                                                {t('organization')}
                                            </Text>
                                        </Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {orgsToShareWith.map(item => (
                                        <Tr
                                            key={item.id}
                                            onClick={() =>
                                                handleCheckboxChange(item.id)
                                            }
                                        >
                                            <Td textAlign='center'>
                                                <Checkbox
                                                    colorScheme='gray'
                                                    isChecked={shareOrgs.includes(
                                                        item.id
                                                    )}
                                                    onChange={() =>
                                                        handleCheckboxChange(
                                                            item.id
                                                        )
                                                    }
                                                />
                                            </Td>
                                            <Td>
                                                <Text variant='headingSm'>
                                                    {item.name}
                                                </Text>
                                            </Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </VStack>
                    </VStack>
                )}
            </VStack>
        </OrgPageModal>
    );
};

export default UploadDataModal;
